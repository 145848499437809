var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"diseCls"},[_c('div',{staticClass:"titleCls"}),_vm._m(0),_c('div',{staticClass:"technologyCls"},[_vm._m(1),_c('div',[_vm._m(2),_vm._m(3),_c('div',{staticClass:"middle"},[_c('div',{staticClass:"goOfficialWebsite mt50",on:{"click":function($event){return _vm.jinfengTechnology()}}},[_vm._v(" 进入官网 ")])]),_vm._m(4)])]),_c('div',{staticClass:"IntelligentCls"},[_vm._m(5),_c('div',[_vm._m(6),_vm._m(7),_c('div',{staticClass:"middle"},[_c('div',{staticClass:"goOfficialWebsite",on:{"click":function($event){return _vm.intelligentCreation()}}},[_vm._v(" 进入官网 ")])]),_vm._m(8)])])]),_vm._m(9),_c('div',{staticClass:"copy_right"},[_c('span',[_vm._v("Copyright © 2018 www.goldbeacon.cn. All Rights Reserved    湖南金烽信息科技有限公司版权所有    "),_c('a',{staticClass:"skipCls",on:{"click":_vm.skip}},[_vm._v("湘ICP备18016577号-1")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"connection"},[_c('span',[_vm._v("合作咨询电话： 0731-89917763")]),_c('div',{staticClass:"contactUs"},[_vm._v("联系我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"middle"},[_c('img',{staticClass:"middleImg",attrs:{"src":require("../assets/homePageimg/keji.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"middle"},[_c('div',{staticClass:"neirong"},[_vm._v("专注央国企管理软件和解决方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"middle"},[_c('div',{staticClass:"neirongOne"},[_vm._v("专注于大数据、管理、智慧交通行业为央国企提供数字化产品及解决方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"middle",staticStyle:{"display":"none"}},[_c('div',{staticClass:"corporation"},[_vm._v("湖南金烽科技信息有限公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"middle"},[_c('img',{staticClass:"middleImg",attrs:{"src":require("../assets/homePageimg/zhizao.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"middle"},[_c('div',{staticClass:"neirong"},[_vm._v("从咨询问诊到战略规划，分步有效落地数字化转型战略")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"middle"},[_c('div',{staticClass:"neirongOne"},[_vm._v("用新技术、新方法辅助管理者解决生产执行中的管理难题优化并创新业务价值，最终实现成本优化和盈利。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"middle",staticStyle:{"display":"none"}},[_c('div',{staticClass:"corporation"},[_vm._v("杭州金烽智造科技有限公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottomCls"},[_c('div',{staticClass:"bottomLeft"},[_c('img',{attrs:{"src":require("../assets/homePageimg/logo.png"),"alt":""}}),_c('div',[_c('div',{staticClass:"telephone"},[_vm._v("合作咨询电话: ")]),_c('div',{staticClass:"telephone"},[_vm._v("0731-89917763")]),_c('span',{staticClass:"address"},[_vm._v("长沙高新区五矿麓谷科技园A4栋6楼 ")])])]),_c('div',{staticClass:"bottomMiddle"},[_c('div',{staticClass:"interval"},[_c('div',{staticClass:"bottomMiddleTitle"},[_vm._v("软件服务")]),_c('div',{staticClass:"bottomMiddleContent"},[_c('div',{staticClass:"marBot"},[_vm._v("数字化服务")]),_c('div',{staticClass:"marBot"},[_vm._v("可视化大屏")])])]),_c('div',{staticClass:"interval"},[_c('div',{staticClass:"bottomMiddleTitle"},[_vm._v("金烽智造")]),_c('div',{staticClass:"bottomMiddleContent"},[_c('div',{staticClass:"marBot"},[_vm._v("金指云")]),_c('div',{staticClass:"marBot"},[_vm._v("智能制造")])])]),_c('div',{staticClass:"interval"},[_c('div',{staticClass:"bottomMiddleTitle"},[_vm._v("联系我们")]),_c('div',{staticClass:"bottomMiddleContent"},[_c('div',{staticClass:"marBot"},[_vm._v("公司介绍")]),_c('div',{staticClass:"marBot"},[_vm._v("联系我们")])])])]),_c('div',{staticClass:"bottomRight"},[_c('img',{attrs:{"src":require("../assets/img/erweima.png"),"alt":""}}),_c('div',{staticClass:"subscribeTo"},[_vm._v("扫一扫关注订阅号")])])])
}]

export { render, staticRenderFns }