<template>
  <div>
  <div class="diseCls">
    <div class="titleCls"></div>
    <div class="connection">
      <span>合作咨询电话： 0731-89917763</span>
      <div class="contactUs">联系我们</div>
    </div>

    <div class="technologyCls" >
      <div class="middle">
        <img src="../assets/homePageimg/keji.png" alt="" class="middleImg">
      </div>
      <div>
        <div class="middle">
          <div class="neirong">专注央国企管理软件和解决方案</div>
        </div>
        <div class="middle">
          <div class="neirongOne">专注于大数据、管理、智慧交通行业为央国企提供数字化产品及解决方案</div>
        </div>
        <div class="middle">
          <div class="goOfficialWebsite mt50" @click="jinfengTechnology()">
            进入官网
          </div>
        </div>
        <div class="middle" style="display: none;">
          <div class="corporation">湖南金烽科技信息有限公司</div>
        </div>
      </div>
    </div>

    <div class="IntelligentCls">
      <div class="middle">
        <img src="../assets/homePageimg/zhizao.png" alt="" class="middleImg">
      </div>
      <div>
        <div class="middle">
          <div class="neirong">从咨询问诊到战略规划，分步有效落地数字化转型战略</div>
        </div>
        <div class="middle">
          <div class="neirongOne">用新技术、新方法辅助管理者解决生产执行中的管理难题优化并创新业务价值，最终实现成本优化和盈利。</div>
        </div>
        <div class="middle">
          <div class="goOfficialWebsite"  @click="intelligentCreation()">
            进入官网
          </div>
        </div>
        <div class="middle" style="display: none;">
          <div class="corporation">杭州金烽智造科技有限公司</div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottomCls">
      <div class="bottomLeft">
          <img src="../assets/homePageimg/logo.png" alt="">
        <div>
          <div class="telephone">合作咨询电话: </div>
          <div class="telephone">0731-89917763</div>
          <span class="address">长沙高新区五矿麓谷科技园A4栋6楼 </span>
        </div>
      </div>

      <div class="bottomMiddle">
        <div class="interval">
          <div class="bottomMiddleTitle">软件服务</div>
          <div class="bottomMiddleContent">
            <div class="marBot">数字化服务</div>
            <div class="marBot">可视化大屏</div>
          </div>
        </div>

        <div class="interval">
          <div class="bottomMiddleTitle" >金烽智造</div>
          <div class="bottomMiddleContent">
            <div class="marBot">金指云</div>
            <div class="marBot">智能制造</div>
          </div>
        </div>

        <div class="interval">
          <div class="bottomMiddleTitle" >联系我们</div>
          <div class="bottomMiddleContent">
            <div class="marBot">公司介绍</div>
            <div class="marBot">联系我们</div>
          </div>
        </div>
      </div>

      <div class="bottomRight">
          <img src="../assets/img/erweima.png" alt="">
          <div class="subscribeTo">扫一扫关注订阅号</div>
      </div>
    </div>

    <div class="copy_right">
      <span>Copyright © 2018 www.goldbeacon.cn. All Rights Reserved &nbsp;&nbsp;&nbsp;湖南金烽信息科技有限公司版权所有
        &nbsp;&nbsp;&nbsp;<a @click="skip" class="skipCls">湘ICP备18016577号-1</a></span>
    </div>
</div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    intelligentCreation() {
      this.$router.push('/intelligent')
    },
    jinfengTechnology() {
      this.$router.push('/tech')
    },
    skip(){
      window.open('https://beian.miit.gov.cn/', '_blank');
    }
  },
}
</script>
<style lang="scss" scoped>
.skipCls{
  cursor: pointer;
}
.diseCls {
  position: relative;
  width: 100%;
  height: calc(100vh - 213px);
  // height: 749px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/homePageimg/dise.png');
}

.copy_right {
  // position: absolute;
  // bottom: 0;
  width: 100%;
  height: 39px;
  background: #1C2834;
  color: #75818A;
  line-height: 39px;
  text-align: center;
  font-size: 14px;

  span {
    font-size: 14px;
  }
}

.titleCls {
  left: 260px;
  top: 18px;
  position: absolute;
  width: 162px;
  height: 52px;
  background-size: contain;
  background-image: url('../assets/homePageimg/logo.png');
}

.connection {
  position: absolute;
  display: flex;
  align-items: center;
  right: 260px;
  top: 18px;
  color: #FFFFFF;
  font-size: 20px;
}

.contactUs {
  background: #F69519;
  width: 96px;
  height: 36px;
  border-radius: 4px;
  text-align: center;
  line-height: 36px;
  margin-left: 16px;
}

.middle {
  display: flex;
  justify-content: center
}
.middleImg{
  width: 215px;
  height: 54px;
  margin-top: 75px;
  margin-bottom: 48px;
}

.technologyCls {
  background-image: url('../assets/homePageimg/bg.png');
  position: absolute;
  width: 604px;
  height: 588px;
  background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  left: 260px;
  top: 110px;
  cursor: pointer;
}

.neirong {
  font-size: 26px;
  font-weight: 400;
  color: #FFFFFF;
  margin-bottom: 41px;
  padding: 0 16%;
}

.neirongOne {
  font-size: 18px;
  font-weight: 400;
  color: #9AB2D2;
  margin-bottom: 35px;
  padding: 0 16%;
}

.corporation {
  font-weight: 400;
  color: #94C2FF;
  font-size: 14px;
}

.goOfficialWebsite {
  width: 204px;
  height: 62px;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 22px;
  margin-bottom: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  background-image: url('../assets/homePageimg/entrance.png');
}


.IntelligentCls {
  background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  background-image: url('../assets/homePageimg/bg.png');
  position: absolute;
  width: 604px;
  height: 588px;
  background-repeat: no-repeat;
  right: 320px;
  top: 110px;
  cursor: pointer;
}

.bottomCls {
  display: flex;
  position: relative;
  width: 100%;
  height: 174px;
  background: #151826;
  color: #FFFFFF;
}

.bottomLeft{
  display: flex;
  margin-left: 260px;
  padding-top: 45px;
  img{
    width: 225px;
    height: 70px;
    margin-right: 44px;
  }
  .telephone{
  font-size: 24px;
  color: #F9F9F9;
  font-weight: 800;
  }
  .address{
    font-size: 14px;
    color: #FFFFFF;
  }
}

.bottomMiddle{
  display: flex;
  padding-left: 204px;
  padding-top: 46px;
  .interval{
    margin-right: 96px;
  }
  .bottomMiddleTitle{
    color: #F9F9F9;
    font-size: 20px;
    margin-bottom: 12px;
  }
  .bottomMiddleContent{
    color: #C9C9C9;
     font-size: 14px;
  }
  .marBot{
    line-height: 24px;
    color: #C9C9C9;
    font-size: 14px;
    margin-bottom: 12px;
  }
}


.bottomRight{
  padding-top: 37px;
  padding-left: 50px;
  img{
    width: 101px;
    height: 101px;
  }
  .subscribeTo{
    font-size: 12px;
    color: #F9F9F9;
    margin-top: 5px;
  }
}

.mt50{
  margin-top: 60px;
}

</style>
